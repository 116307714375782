.bg-custom {
  background-color: #1104c9;

  transition: all .2s ease-in-out;
}


.search-bar-component {
  position: relative;
  padding: 120px 0 42px;
  border-bottom: 1px solid $ash;

  .search-form {
    width: 100%;
  }

  .calendar-wrapper {
    position: relative;
    top: -18px;
  }

  .btn-search {
    position: absolute;
    top: 1px;
    right: 1px;
    z-index: 5;

    width: 48px;
    height: 46px;

    transition: all .3s ease-in-out;

    background-color: #fff;
    border: none;
    border-left: 1px solid $light-grey;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    img {
     opacity: .5; 
    }

    &:hover {
      background-color: #F8F9FA;
    }
  }

  .search-wrapper {
    position: relative;

    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 600px;

    .input-group {
      flex: 0 0 83%;
      width: auto;
    }

    .input-group-text {
      background: $white;
      border-right: none;
    }

    .form-control {
      // border-left: none;
      padding-right: 48px;

      color: #393e46;

      border-radius: 5px;

      &::placeholder {
        color: #606167;
      }

      &:focus {
        outline: none;
        background-color: transparent;
      }
    }

    .lbl-from-to {
      margin-right: 5px;
      width: 100px;

      font-size: 14px;
    }
  }

  .btn-calendar {
    margin-left: 10px;

    background: none;
    border: none;

    .icon {
      // color: $sand-gray;
      color: #d0d0d0;

      &:hover {
        opacity: .5;
      }
    }
  }
}