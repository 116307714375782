.row-wrapper {
  align-items: center;
  list-style-type: none;
  display: flex;
  margin: 0;
  list-style: none;
  padding: 0 0 0 20px;
  border-bottom: 1px solid #cbcbcb;
  background: #fff;

  &:last-child {
    border-radius: 0 0 5px 5px;
    border-bottom: 1px solid #cbcbcb;

    .table-data {
      border: none;
      border-radius: 0 0 5px 5px;
    }
  }

  .table-data {
    border-top: 0;
    border-right: 0;
    border-left: 0;
  }

  .case-link {
    &:hover {
      opacity: .2;
    }
  }
}

.line-chart-wrapper {
  width: 150px !important;
  height: 40px !important;

  .chartjs-render-monitor {
    height: 40px !important;
  }
}