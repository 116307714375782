.chart-row-wrapper {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin-bottom: 50px;

  .doughnut-holder {
    // position: relative;

    display: flex;
    // justify-content: center;
    align-items: center;
    padding: 0 15px;
    height: 80%;

    canvas {
      position: relative;
      z-index: 3;
    }
  }

  .doughnut-wrapper {
    position: relative;

    width: 60%;

    &:first-child {
      width: 60%;
    }

    &:last-child {
      display: flex;
      justify-content: space-between;
      width: 35%;
    }
  }

  .doughnut-col {
    .label {
      font-weight: 400;
      font-size: 13px;
    }

    ul {
      padding: 0;
      margin-bottom: 0;

      list-style: none;

      li {
        padding: 6px 0;

        font-weight: 400;
        font-size: 13px;
      }
    }

    .doughnut-score-range {
      li {
        position: relative;

        padding-left: 20px;
        width: 55px;

        text-align: right;

        &:before {
          content: '';

          position: absolute;
          top: 50%;
          left: 5px;

          transform: translateY(-50%);

          width: 10px;
          height: 10px;
        }

        &:nth-child(1) {
          &:before {
            background-color: rgba(245,164,163,1);
          }
        }

        &:nth-child(2) {
          &:before {
            background-color: rgba(255,218,129,1);
          }
        }

        &:nth-child(3) {
          &:before {
            background-color: rgba(155,219,193,1);
          }
        }
      }
    }

    .doughnut-results {
      li {
        text-align: right;
      }
    }
  }

  .doughnut-content {
    position: absolute;
    top: 50%;
    left: 50%;
    
    text-align: center;
    transition: 2s ease-in;
    transform: translate(-50%, -50%);

    .total {
      font-weight: 800;
      font-size: 25px;
      color: #5f6267;
    }

    .doughnut-content-text {
      font-weight: 400;
      font-size: 8px;
      color: #5f6267;
    }
  }

  .chart-wrapper {
    // flex: 0 0 48%;
    background: $white;
    border: 1px solid $ash;
    border-radius: 5px;

    &.doughnut {
      // flex: 0 0 48%;
      // width: 42%;
      width: 43%;
      margin-right: 2%;
    }

    &.bar {
      // flex: 0 0 50%;
      width: 55%;
    }

    .bar-wrapper {
      padding: 10px;
    }

    .title-wrapper {
      position: relative;

      padding: 15px 20px 15px 50px;

      border-bottom: 1px solid $ash;

      .icon {
        position: absolute;
        top: 50%;
        left: 15px;

        transform: translateY(-50%);
        
        width: 30px;
      }
    }
  }
}

// @include respond-to(xl) {
//   .chart-row-wrapper {
//     .doughnut-holder {
//       canvas {
//         height: 232px!important;
//         width: 458px!important;
//         object-fit: contain;
//       }
//     }
//   }
// }