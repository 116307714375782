//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.header {
  position: fixed;
  z-index: 9;
  
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  background-color: #fff;
  // box-shadow: -1px 7px 12px -6px rgba(0, 0, 0, 0.27);
  border-bottom: 1px solid $light-grey;

  .user-icon-wrapper {
    // padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    
    border: 1.5px solid #606167;
    border-radius: 100%;
  }

  .header-wrapper {
    position: relative;
    
    display: flex;
    padding: 0 24px;
    width: 100%;
  }

  .navbar-brand {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 2rem;

    &:before {
      content: '';

      position: absolute;
      top: 50%;
      right: -2.1rem;
      
      transform: translateY(-50%);
      
      width: 1px;
      height: 64%;
      
      background: #e9e9e9;
    }
  }

  .navbar-nav {
    justify-content: center;
  }

  .menu-list {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;

    list-style: none;
  }

  .image-holder {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .user-img {
    height: 30px;
  }

  .logo-img {
    height: 54px;
    padding: 9px 0;
  }

  .menu-item {
    display: flex;
    align-items: center;
    height: 100%;

    transition: 0.1s ease-in;

    @include on-event {
      transition: 0.1s ease-in;

      .menu-link {
        background-color: #F8F9FA;
      }
    }
  }

  .menu-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    width: auto;
    min-width: 100px;
    // margin-right: 5px;
    height: 100%;
    min-height: 78px;

    font-size: 14px;
    font-weight: 400;
    color: $dark-grayish-navy;
    text-decoration: none;

    &.active {
      font-weight: 700;

      background-color: #e9e9e9;
    }
  }

  //user dropdown menu
  .user-logged {
    position: absolute;
    top: 50%;
    right: 24px;

    display: flex;

    transform: translateY(-50%);
  }

  .btn-user-menu {
    position: relative;

    padding: 0 5px;
    margin-right: 13px;
    width: 32px;
    height: 32px;

    text-align: center;
    
    border-radius: 50%;

    &:hover {
      background-color: #e9e9e9;
    }

    @include on-event {
      box-shadow: none;
      outline: 0;
    }

    .hasNotif {
      width: 15px;
      height: 15px;
      background: red;
      border-radius: 50%;
      font-size: 8px;
      color: white;
      position: absolute;
      top: 0;
      right: 0;
      justify-content: center;
      display: flex;
      align-items: center;
      border: 1px solid white;
    }
  }

  .menu-icon {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;

    width: 4px;
    height: 100%;
    background: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='825 0 270 1080' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Ccircle fill='%233D3D3D' cx='960' cy='540' r='135'/%3E%3Ccircle fill='%233D3D3D' cx='960' cy='945' r='135'/%3E%3Ccircle fill='%233D3D3D' cx='960' cy='135' r='135'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A") center no-repeat;

    transform: translate(-50%, -50%);
  }

  .option-holder {
    display: flex;
    align-items: center;
    margin-right: 12px;

    .dropdown-item {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  .dropdown-list {
    color: #393E46;

    .download-text {
      display: flex;
      align-items: center;

      .icon {
        margin-left: 2px;
      }
    }
  }

  .dropdown-toggle {
    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    left: initial!important;
    right: 0!important;

    padding: 0;

    // transform: translate3d(-23px, 44px, 0)!important;
    transform: translate3d(0, 44px, 0)!important;

    @include on-event {
      outline: none;
    }
  }

  .dropdown-item {
    padding: 0.7rem 1.5rem;

    font-size: 15px;

    @include on-event {
      color: #16181b;
      background-color: #f5f5f5;
    }
  }

  .dropdown-toggle {
    padding: 0;
    margin: 0;

    border: none;
  }

  .profile-dropdown {
    display: flex;

    .dropdown-item {
      &:not(:last-child) {
        border-bottom: 1px solid #d9d9d9; 
      }
    }

    .dropdown-menu {
      color: #393E46;

      border: 1px solid #d9d9d9;
      // border-radius: 6px;
    }

    .profile-fullname {
      font-size: 21px;
      margin-bottom: 2px;
    }

    .profile-user-name {
      margin-bottom: 2px;
    }

    p {
      font-size: 15px;
    }

    .dropdown-list {
      img {
        margin-right: 15px;
      }
    }


    a {
      display: flex;
      align-items: center;
    }
  }

  .profile-info {
    padding: 0.7rem 1.5rem;

    border-bottom: 1px solid #d9d9d9; 
  }

  .profile-item {
    pointer-events: initial;

    @include on-event {
      background-color: transparent;
    }
  }
}

.login-header {
  position: absolute;
  top: 55px;

  display: block;
  width: 100%;

  img {
    width: 183px;
  }
}

.reset-password-header {
  position: absolute;
  top: 55px;

  display: block;
  width: 100%;

  text-align: center;

  img {
    width: 130px;
  }
}

.recover-password-header {
  position: absolute;
  top: 55px;

  display: block;
  width: 100%;

  text-align: center;

  img {
    width: 120px;
  }
}

.account-activation-header {
  position: absolute;
  top: 55px;

  display: block;
  width: 100%;

  text-align: center;

  img {
    width: 120px;
  }
}

@include respond-to(md) {
  .header {
    .navbar-brand {
      margin-right: 30px;
      width: 100px;
    }

    .menu-link {
      padding: 29px 15px 31px;
    }

    .btn-user-menu {
      margin-left: 20px;
    }
  }
}

@include respond-to(sm-down) {
  .header {
    .navbar-brand {
      margin-right: 25px;
      width: 105px;
    }

    .menu-link {
      padding: 29px 15px 22px;
    }

    .option-holder {
      display: flex;
      align-items: center;
    }

    .menu-icon {
      height: 20px;
    }
  }
}


.custom-bar-wrapper {
  display: none;
  transition: all .5s ease-in-out;
  &.loading {
    display: block;
    .custom-bar {
      position:absolute;
      top:100%;
      left:50%;
      transform:translate(-50%,-50%);
      width:100%;
      height:1px;
      background:#f2f2f2;
      overflow:hidden;
    }
    .custom-bar div:before {
      content:"";
      position:absolute;
      top:0px;
      left:0px;
      bottom:0px;
      background:#1104c9;
      animation:box-1 1000ms cubic-bezier(0.65,0.81,0.73,0.4) infinite;
    }
    .custom-bar div:after {
      content:"";
      position:absolute;
      top:0px;
      left:0px;
      bottom:0px;
      background:#1104c9;
      animation:box-2 1000ms cubic-bezier(0.16,0.84,0.44,1) infinite;
      animation-delay:9000ms;
    }
  }
}


@keyframes box-1 {
  0% {
    left:-35%;
    right:100%;
  }
  60%,100% {
    left:100%;
    right:-90%;
  }
}
@keyframes box-2 {
  0% {
    left:-200%;
    right:100%;
  }
  60%,100% {
    left:107%;
    right:-8%;
  }
}