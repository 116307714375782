.case-management {
  .sub-header {
    width: 100%;

    background-color: #fff;
    border-top: 1px solid $light-grey;
  }

  .sub-header-wrapper {
    padding: 0 15px;
    margin: auto;
    max-width: 1640px;
  }

  .sub-header-link-holder {
    padding: 0;
    margin: 0;
    display: flex;

    list-style: none;
  }

  .sub-header-item {
    margin: 0 10px;
  }

  .sub-header-link {
    position: relative;

    display: block;
    padding: 8px 5px;

    font-weight: 400;
    font-size: 14px;
    color: $dark-gray;

    &.active {
      font-weight: 700;
    }
    
    &.active,
    &:hover {
      color: #1104C9;
      
      
      &:before {
        content: '';

        position: absolute;
        bottom: -1px;
        left: 0;

        width: 100%;
        height: 2px;
        
        background-color: #1104C9;
      }
    }
  }
}