//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

///Roboto font family
$font-roboto: 'Roboto';

/// Regular font family
$font-opensans: 'Open Sans';

/// @type List
$text-font-stack: $font-opensans, 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

/// Copy text color
/// @type Color
$text-color: #5f6267 !default;

/// Main brand color
/// @type Color
$brand-color: #1103c8 !default;

/// Light grey
/// @type Color
$light-grey: #cbcbcb !default;

/// Medium grey
/// @type Color
$mid-grey: #999 !default;

/// Dark grey
/// @type Color
$dark-grey: #444 !default;

$gray9: #a9a8a9;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '../../assets/' !default;

$blue: #412ED4;
$dark-blue: #1204c9;
$blue-gray: #0e039d;
$navy-blue: #0b037a;
$royal-blue: #4436eb;
$alice-blue: #f8f9fb;
$medium-purple: #7068df;
$light-purple: #b7b3ee;
$pale-navy: #efeefe;
$red: #fd1e4d;
$bright-red: #fd4a71;
$black: #000;
$dark-grayish-navy: #31353e;
$dark-gray: #606167;
$sand-gray: #525252;
$gray: #5f6267;
$ash: #dbdbdb;
$white: #fff;