.filter-options {
  .dropdown {
    margin-right: 10px;
  
    button {
      &.dropdown-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 215px;
        
        font-size: 14px;
        color: #606167;
    
        background-color: white;
        border-color: #CBCBCB;


        &:focus {
          box-shadow: none;
        }
    
        &.btn {
          &.btn-secondary {
            &:active {
              color: #606167;
              background-color: white;
            }
          }
        }
      }
    }
  } 

  .dropdown-menu {
    padding: 0;
    min-width: 215px;
  }

  .dropdown-item {    
    padding: 6px 20px;

    font-size: 14px;

    border-bottom: 1px solid #e5e5e5;

    .active,
    &:active {
      background-color: white;
      color: #606167;
    }
  }
}