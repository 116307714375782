.user-profile {
  padding: 128px 0 64px;
  background: #f8f9fb;

  .form-group {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .user-profile-content {
    display: flex;
    flex-wrap: wrap;
  }

  .tab-content {
    width: 100%;
  }

  h1 {
    margin-bottom: 0;
    margin-left: 11px;

    font-size: 22px;
    font-weight: 800;
    color: #393e46;
  }

  .nav {
    display: block;
    padding: 74px 0;
    width: 24%;

    background: $white;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
  }

  .nav-link {
    padding: 13px 23px;

    font-size: 16px;
    font-weight: 100;
    color: #393e46;
    
    border: none;
    border-radius: 0;
    cursor: pointer;

    &.active {
      font-weight: 800;
      background: #e9e9e9;
    }

    &:hover {
      background:#f8f9fa;
    }
  }

  .section-profile {
    // position: relative;
    // left: 9px;
    padding-left: 19px;
    margin: 0 auto;
    width: 76%;
    // padding: 63px 0;
    max-width: 1054px;
    border-radius: 7px;
  }

  .content {
    // padding: 58px 63px;
    // padding: 41px 76px;

    background: $white;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
  }

  .profile {
    width: 92px;
    height: 92px;
    object-fit: cover;

    border-radius: 100%;
  }

  .profile-header {
    display: flex;
    align-items: center;
    padding: 20px 20px;
    // background: $white;
    border-bottom: 1px solid #e3e3e3;
  }

  .profile-footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px;
    // background: $white;
    border-top: 1px solid #e3e3e3;
  }

  .profile-info {
    text-align: center;
  }

  .profile-title-button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 37px;
    padding-bottom: 20px;

    border-bottom: 1px solid #e3e3e3;
  }

  .t-h1 {
    margin-bottom: 0;

    color: $black;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 38%;
  }

  .button-wrapper-password {
    flex-wrap: nowrap;
    width: 400px;
  }

  .btn-custom {
    padding: 6px 0;
    max-width: 48%;
  }

  .greetings {
    margin-top: 24px;

    font-size: 17px;
    font-weight: 700;
    color: #393e43;
  }

  .manage {
    margin-top: -3px;

    font-size: 17px;
    font-weight: 700;
    color: #6a6d72;
  }

  .user-icon-wrapper {
    // padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    
    border: 1.5px solid #606167;
    border-radius: 100%;
  }

  .form-wrapper {
    padding: 30px 76px 50px;
    width: 100%;

    background: $white;
  }

  .form-control {
    font-size: 16px;

    pointer-events: none;
    opacity: 0.5;

    &.enable {
      pointer-events: initial;
      opacity: 1;
    }
  }

  .select-holder {
    pointer-events: none;
    
    &:after {
      content: '';
      display: none;
    }
  }

  label {
    margin-bottom: 6px;

    font-size: 17px;
    font-weight: 800;

    font-weight: 400;
    color: #393e46;
  }
}

@include respond-to(md) {
  .user-profile {
    form {
      padding: 32px 0;
    }

    .content {
      padding: 58px 32px;
    }

    .button-wrapper {
      width: auto;
    }
  }
}

@include respond-to(sm-down) {
  .user-profile {
    form {
      flex-wrap: wrap;
      padding: 32px 0;
    }

    .content {
      padding: 58px 32px;
    }

    .button-wrapper-password {
      flex-wrap: nowrap;
      width: 100%;
    }

    .form-wrapper {
      width: 100%;
    }

    .btn-custom {
      max-width: 100%;
    }

    .btn-outline-blue {
      margin-bottom: 15px;
    }
  }
}