.modal {
  .close {
    opacity: 1;
  }

  .dropdown-item {
    .icon {
      top: -3px;
    }
  }
}

#remove-popup, #upgrade-popup,#sankey-popup{
  .close {
    // width: 24px;
    position: relative;
    top: 0;
  }

  svg{
    width: 100%;
    top: 0;
    overflow: auto;
    //height: 100%;
  }

  display: none!important;
  position: relative;
  right: initial;

  margin: 0 auto!important;

  color: #393E46;
  // border: 1px solid $light-grey;
  .custom-map-modal {
    .modal-dialog {
     width: 100%;
     height: 100%;
     margin: 0;
     padding: 0;
   }
  }

  .modal-content {
    min-height: auto;
    border-radius: 5px;
  }

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-header {
    padding-right: 1rem;
    padding-bottom: 0.8rem;
    padding-left: 1.2rem;

    border: none;

    .modal-title {
      margin-left: 0;

      font-size: 20px;
      font-weight: 700;
      word-wrap: break-word;
      word-break: break-all;
    }

    .close {
      // position: initial; 
      padding: initial; 
      margin: initial;
    }
  }

  .modal-body {
    padding: 0 1.2rem;
  }

  .description {
    font-size: 14.5px;
    font-weight: 400;
  }

  .modal-footer {
    padding-top: 3rem;
    padding-right: 1rem;
    padding-left: 1rem;
    // padding: 0.5rem 6rem;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    float: right;

    border: none;
  }

  .btn-custom {
    // padding: 6px 0;
    width: 100%;
    max-width: 110px;
  } 
}

.show {
  #remove-popup,#upgrade-popup,#sankey-popup {
    display: flex!important;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
}
