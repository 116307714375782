.breakdown-block {
  display: flex;
  flex-wrap: wrap;

  color: #393e46;
  //$dark-gray;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;

  .breakdown-title,
  .figure-wrapper,
  .breakdown-item {
    flex: 0 0 100%;
  }

  &:first-child {
    .breakdown-title {
      font-weight: 300;
    }

    .figure-wrapper {
      .figure-value {
        font-size: 45px;
        font-weight: 300;
      }
    }

    .breakdown-string,
    .breakdown-value {
      font-weight: 250;
    }
  }

  .breakdown-title {
    font-size: 15px;
  }
  
  .figure-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10;
    margin: 5px 10;
    min-height: 48px;

    border-top: 1px solid $dark-gray;
    border-bottom: 1px solid $dark-gray;

    .figure-title {
      font-weight: 100;
      margin-bottom: 6px;
    }

    .figure-title,
    .figure-subtitle {
      line-height: 11px;
    }

    .figure-subtitle {
      font-weight: 100;
    }

    .figure-value {
      font-size: 45px;
      font-weight: 380;
      line-height: 1.4;
    }

    .figure-head {
      text-align: end;
    }
  }

  .breakdown-item {
    & > div {   
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .breakdown-figure-wrapper {
      display: flex;

      .breakdown-value {
        margin-right: 15px;
      }

      .breakdown-percentage {
        font-weight: 200;
      }
    }
  }

  .bdown-values {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2px 0;

    border-bottom: 1px 
    dotted #606167;
    //solid #e5e5e5;
    //border-bottom-style: dashed;

    .breakdown-figure-wrapper {
      display: flex;
    }
  }
}