.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 0;
  // margin-top: 20px;
  // margin-bottom: 20px;

  .option,
  option {
    font-size: 15px;
    font-weight: 200;
    color: $white;
    background-color: #858587;
    box-shadow: 5px 10px #888888;
  }

  select::selected {
    background-color: red;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  li {
    margin: 0 11px;

    &:first-child,
    &:last-child {
      display: none;
    }
  }

  a {
    display: flex;
    justify-content: center;

    font-size: 15px;
    color: #dfe0e2;

    @include on-event {
      text-decoration: none;
    }
  }

  .icon {
    font-size: 15px;
    color: #464952;
  }

  .active {
    padding: 0 7px;
    
    font-size: 15px;
    font-weight: 400;

    border-radius: 3px;
    border: 1px solid #72757b;
    background: #e9e9e9;

    a {
      color: #464952;
    }
  }

  .select-holder {
    width: 100%;
    max-width: 140px;
    
    select {
      color: #393e46;
      
      background: transparent;
    }

    &:after {
      color: #393e46;
    }
  }

  select {
    padding: 0 10px; 
    width: 140px;

    font-size: 16px;
    font-weight: 400;

    border: none;

    &:focus {
      outline: none
    }
  }
}