//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Basic styles for links
 */

body {
  margin: 0;
  padding: 0 !important;

    // & > div:not(#root){
    //   position: static !important;
    // }

    // & > div:not(.daterangepicker){
    //   position: static !important;
    // }
}

.container {
  margin: 0 auto;
}

p {
  margin-bottom: 0;
}

a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: none;
  }
}

.btn-custom,
a {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;

  @include on-event {
    outline: 0;
    @include boxshadow(none);
  }
}

/**
 * Basic styles for input fields
 */
input[type="email"],
input[type="password"],
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-focus-ring-color: none;
  outline: none;

  @include on-event {
    @include boxshadow(none);
  }
}

// input[type="password"] {
//   -webkit-text-security: square;
// }

input[type="password" i] {
  -webkit-text-security: square !important;
}

//styles for navbar-toggler
.navbar-toggler {
  padding: 10px 0;

  border: 0;
  border-radius: 0;

  .icon-bar {
    display: block;
    height: 4px;
    width: 29px;

    background: #000;

    @include transition(0.5s cubic-bezier(0.65, 0.05, 0.36, 1));

    @include boxshadow(none);

    &+.icon-bar {
      margin-top: 4px;
    }
  }

  &[aria-expanded="true"] {
    .icon-bar {
      &:first-child {
        @include transform(rotate(225deg) translate(-6px, -5px));
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        @include transform(rotate(-225deg) translate(-6px, 6px));
      }
    }
  }

  @include on-event() {
    outline: 0;
  }
}

@include respond-to(xl) {
  .container {
    max-width: 1024px;
  }
}

@include respond-to(sm-down) {
  .container {
    max-width: 700px;
  }
}

.table-row.title {
  cursor: pointer;
}

.table-row.arrow {
  position: relative;
  &:after {
    content: '';

    position: absolute;
    top: -2px;
    right: -23px;
    bottom: 0;

    width: 17px;
    height: 22px;
    background: url("../../assets/images/arrow-down.svg") center no-repeat;
    background-size: 17px;
  }
}

.arrowSort {
  position: relative;
  opacity: 0;

  &:after {
    content: '';
    
    position: absolute;
    top: -2px;
    right: -23px;
    bottom: 0;

    width: 17px;
    height: 22px;
    background: url("../../assets/images/arrow-down.svg") center no-repeat;
    background-size: 17px;
  }

  &.visible {
    opacity: 1;
    transition: .2s ease-out;
  }

  &.active {
    opacity: 1;
    transition: .2s ease-out;
    
    &:after {
      transform: rotate(180deg);
    }
  }
}

//svg,
.icon,
.search-icon {
  width: 20px;
  color: #393e46;
}

#sankey-popup {
  .modal-body{
    svg{
      width: 100%;
      top: 0;
      overflow: auto;
      //height: 100%;

      g{
        top:0
      }
    }
  }
}