.case-management-content {
  padding: 78px 0 0;

  background-color: #F8F9FB;

  .breakdown {
    padding: 20px 0 0;
  }

  .breakdown-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 25px 25px 29px 25px;
    
    background-color: $white;
    border: 1px solid #cbcbcb;
    border-radius: 5px;

    .breakdown-block {
      flex: 0 0 30%;
    }
  }

  .filter-options {
    .dropdown {
      margin: 0;
      width: 212px;

      &:not(:last-child) {
        margin-right: 20px;
      }

      button.dropdown-toggle {
        padding: 7px 15px;
        width: 100%;
        min-height: 40px;

        font-size: 16px;
        color: #495057;
      }
    }

    .dropdown-menu {
      min-width: 100%;
      max-height: 162px;
      overflow: auto;
    }

  }

  .table-row-title {
    display: flex;
    align-items: center;

    .with-sub {
      display: flex;
      flex-direction: column;
    }

    .subheading {
      font-size: 12px;
      font-weight: 300;
    }

    .arrowSort {
      &:after {
        top: -10px;
      }
    }
  }

  .case-table{
    .noGraph{
      text-align: center;
      margin: 0;
      position: absolute;
      top: 30%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}