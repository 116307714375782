.case-management {
  .content {
    padding-top: 115px;
    min-height: calc(100vh);
  }

  .heading-table {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin: 20px 0;

    background-color: #fff;
    border: 1px solid $light-grey;
    border-radius: 5px;
  }

  .heading-table-col {
    p {
      font-size: 14px;
      letter-spacing: .5px;
      line-height: 1.5;

      span {
        font-weight: 700;
      }
    }

    &:first-child {
      position: relative;
      margin-right: 50px;

      &:after {
        content: '';

        position: absolute;
        top: 50%;
        right: -50px;

        transform: translateY(-50%);

        width: 1px;
        height: 100%;

        background-color: $light-grey;
      }
    }

    &:last-child {
      margin-left: 20px;
    }
  }
}