//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

button {
  &:focus {
    outline: none;
  }
}

.btn,
.btn-custom {
  border-radius: 5px;
  width: !important;
}

.btn-custom {
  padding: 6px 79px;
  width: 100%;
  max-width: 203px;
  
  font-weight: 100;
  font-size: 16px;

  border-radius: 5px;

  cursor: pointer;
}

.btn-blue {
  @extend .btn-custom;

  color: $white;

  background-color: $blue;
  border: 1px solid $blue;

  &:hover {
    opacity: .8;
  }

  &.opaque {
    &.disabled {
      pointer-events: none;
      opacity: .3;
    }
    
    &:hover {
      color: $white;
    
      background-color: $blue;
      
      opacity: .7;
    }
  }
}

.btn-red {
  @extend .btn-custom;
  padding: 6px 0;

  font-size: 15px;
  color: $white;

  background: #FE1E4E;
  border-radius: 5px;
  border: 1px solid #FE1E4E;

  &:hover {
    color: $white;

    opacity: .7;
  }
}

.btn-outline-grey {
  @extend .btn-custom;
  padding: 6px 0;

  font-size: 15px;
  color: #393E46;
  font-weight: 400;
  
  background: transparent;
  border: 1px solid transparent;
  border-radius: 5px;

  &:hover {
    color: #393e46;

    background: #f8f9fa;
    border-color: #f8f9fa;
  }
}

.btn-transparent {
  @extend .btn-custom;
  padding: 6px 0;

  font-size: 16px;
  color: #393e46;
  font-weight: 100;
  
  background: transparent;
  border: 1px solid transparent;
  border-radius: 5px;

  &:hover {
    font-weight: 100;
    color: $black;

    background: #f8f9fa;
  }
}

.btn-hover-light-violet {
  color: $blue;

  background: #e8e6fa;
}

.btn-hover-violet {
  &:hover {
    color: $white;

    background: #5950da;
    border-color: #5950da;
  }
}

.btn-outline-blue {
  @extend .btn-custom;

  color: $blue;

  background-color: $white;
  border: 1px solid $blue;

  &:hover {
    color: $white;

    background-color: $blue;
  }

  &.opaque {
    &:hover { 
      color: $blue;

      background-color: #E8E6FA;
      border: 1px solid $blue;
    }
  }
}

.dropdown-holder {
  .show {
    .btn-secondary {
      color: $blue!important;
      background: #e8e6fa!important;
      border-color: $blue!important;
      box-shadow: none!important;
    }
  }

  .dropdown {
    &.show {
      .dropdown-toggle {
        color: #393e46;
        background: $white;
      }
    }

    .dropdown-item {
      &:first-child {
        border-radius: 7px 7px 0 0;
      }

      &:last-child {
        border-radius: 0 0 7px 7px;
      }

      &:hover {
        color: #212529;

        background-color: #f8f9fa;
        // background-color: #000;
      }
    }
  }

  .icon {
    position: relative;
    top: -2px;
    
    margin-right: 14px;
    width: 16px;
  }

  .dropdown-toggle {
    padding: 6px 47px 6px 12px;
    width: 100%;
    max-width: 203px;
    
    font-size: 16px;
    font-weight: 100;
    text-align: left;
    color: $blue;

    background: $white;
    border: 1px solid $blue;
    border-radius: 5px;

    &:after {
      position: absolute;
      right: 15px;
      top: 45%;

      color: $black;
    }

    &:hover {
      // color: $gray9!important;
      // border: 1px solid $gray9!important;
      background: $white!important;
      box-shadow: none!important;
    }

    &.drop-white {
      &:hover {
        color: $blue!important;
        background: #e8e6fa!important;
      }
    }

    &.drop-blue {
      color: $white!important;
      background: $blue!important;

      &:hover {
        opacity: .7;
      }
    }
  }

  .dropdown-menu {
    left: initial!important;
    right: 0;

    padding: 0;
    // width: 202px;
    width: 154px;

    border-radius: 5px;

    &:hover {
      border-radius: 5px;
    }
  }

  .dropdown-item {
    // padding-top: 10px;
    // padding-bottom: 10px;
    padding: 0.5rem 1.3rem;

    font-weight: 300;

    &:not(:last-child) {
      border-bottom: 1px solid #f1f1f1;
    }
  }
}

.create-case {
  .dropdown-item {
    padding: 0.5rem 0.8rem;
  }

  .dropdown-menu {
    width: 169px;
  }
  
  .dropdown-toggle {
    .icon {
      position: relative;
      top: -1px;

      margin-right: 15px;
      width: 17px;
   }

    &:after {
      display: none;
    }

    .drop-white {
      @include on-event {
        color: $blue!important;
        background: #e8e6fa!important;
      }
    }

    .drop-blue {
      @include on-event {
        color: #e8e6fa!important;
        background: $blue!important;
      }
    }
  }
}

.action-button {
  // margin-left: 22px;
  width: 100%;
  max-width: 100%;

  .dropdown-menu {
    border: 1px solid #f1f1f1;
  }

  .dropdown-item {
    &:not(:last-child) {
      border-color: #f1f1f1;
    }
  }

  .dropdown {
    &.show {
      .dropdown-toggle {
        color: $blue;
      
        background: #e8e6fa;
      }
    }
    .dropdown-item {
      &:first-child {
        border-radius: 7px 7px 0 0;
      }

      &:last-child {
        border-radius: 0 0 7px 7px;
      }

      &:hover {
        color: #212529;

        background-color: #f8f9fa;
        // background-color: #000;
      }
    }
  }

  .dropdown-toggle {
    padding: 5px 11px;
    font-size: 16px;
    color: $white;
    text-align: center;
 
    background: $blue!important;
    border: 1px solid $blue;

    &:hover {
      color: $white !important;

      border: 1px solid $blue;
      background: $blue!important;
      opacity: .8;
    }

    // &.active {
    //   background: rgba(0, 13, 255, 0.23);
    //   color: $blue;
    // }

    &:focus {
      box-shadow: none!important;
      outline: none;
    }

    &:after {
      display: none;
    }
  }
}

.btn-modal-back {
  background: transparent;
  border: none;
  font-size: 14px;
  padding-left: 0;
  color: #CBCBCB;

  img {
    margin-right: 3px;
  }
}

.btn-icon {
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  
  text-align: center;

  border-radius: 50%;

  &:hover {
    background: #e9e9e9;
  }

  &:focus {
    box-shadow: none!important;
    outline: none;
  }
}