.table-illustration {
  position: relative;
  
  min-height: 400px;

  background-color: #fff;
  // border-right: 1px solid #cbcbcb;
  // border-left: 1px solid #cbcbcb;
  // border-bottom: 1px solid #cbcbcb;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  // &.address-transaction {
  //   border-top: 1px solid #cbcbcb;
  // }

  .table-illustration-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);

    width: 100%;
    max-width: 365px;
    
    text-align: center;
  }

  .text-heading {
    font-size: 20px;
    font-weight: 500;
    color: $dark-gray;
  }

  .text-description {
    font-size: 15px;
    color: $dark-gray;

    span {
      font-weight: 500;
    }
  }
}