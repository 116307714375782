//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all styles related to the accordion component.
// -----------------------------------------------------------------------------

.accordion-table {
  min-height: 540px;
  
  font-size: 18px;

  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;

  .table-header,
  .table-data {
    display: flex;
    padding: 0;
    margin: 0;

    list-style: none;

    background: $white;
  }

  .accordion__panel {
    background: $white;
  }
  
  .table-header {
    position: relative;

    padding: 20px;

    border-bottom: 1px solid $light-grey;
    border-radius: 5px 5px 0 0;

    &:nth-child(3) {
      border-radius: 0;
    }
  }

  .table-title {
    display: flex;
    align-items: center;
    padding-left: 36px;

    font-size: 18px;
    font-weight: 400;
    color: #393e46;

    .icon-menu {
      position: absolute;
      top: 50%;
      left: 20px;

      transform: translateY(-50%);
    }
  }

  .table-options {
    display: flex;
    align-items: center;
    margin-left: auto;

    .btn-icon {
      margin-right: 25px;
    }

    .icon {
      cursor: pointer;
    }

    .btn-outline-blue {
      display: flex;
      justify-content: center;
      max-width: 150px;
    }
  }

  .table-data {
    // padding: 24px 53px;
    height: auto;
    min-height: 54.5px;
    padding: 15px 32px;

    .table-row {
      display: flex;
      align-items: center;
    }

    &.filter-options {
      min-height: 0;
      height: 0;
      padding: 0;

      border: none;
      transition: all 0.15s ease-out;


      .form-group,
      .table-select {
        min-height: 0;
        transition: all 0.15s ease-out;
        height: 0;
        padding: 0;
      }

      &.collapse {
        min-height: auto;
        height: auto;
        display: flex;
        justify-content: space-around;
        padding: 30px 0;
        border-bottom: 1px solid #cbcbcb;

        &.date-picker-holder {
          justify-content: flex-end;
          padding: 25px 15px;
        }
        
        transition: all 0.15s ease-in;


        .form-group,
        .table-select {
          min-height: auto;
          height: auto;
          
          transition: all 0.25s ease-in;
        }  

        .table-select {
          padding: 0.7rem .75rem;
        }
      }
    }
  }

  .accordion__item {
    position: relative;

    border-bottom: 1px solid $light-grey;

    &:last-child {
      // border-bottom: none;
      // border-radius: 0 0 5px 5px;
    }

    div[aria-expanded="true"] {
      .table-row-hd {
        display: none;
      }
    }
  }

  .table-row-dropdown {
    position: absolute;
    top: 10px;
    right: 105px;

    width: 137px;
    
    text-align: right;
  }

  .table-row {
    &:first-child {
      position: relative;
      width: 100%;
      display: flex;
      margin-right: 70px;
      max-width: 210px;
      justify-content: center;
    }

    &:nth-child(2) {
      width: 73px;
      margin-right: 60px;
    }

    &:nth-child(3) {
      display: flex;
      justify-content: flex-end;
      margin-right: 80px;
      width: 59px;
    }

    &:nth-child(4) {
      width: 80px;
      margin-right: 70px;
      display: flex;
      justify-content: center;
    }

    &:last-child {
      width: 160px;
    }
  }

  // .accordion__item {
  //   &:first-child {
  //     border-bottom: 1px solid $light-grey;
  //   }

  //   &:last-child {
  //     .table-data {
  //       border-bottom: none;
  //       border-radius: 0 0 5px 5px;
  //     }
  //   }
  // }

  .accordion__button {
    position: relative;

    cursor: pointer;
    transition: all .2s ease-in-out;

    @include on-event {
      border-radius: 0 0 11px 11px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 50px;

      width: 25px;
      height: 8px;

      background: url("../../assets/images/icon-chevron-down.svg") center no-repeat;

      background-size: contain;

      transform: translateY(-50%);
      transition: all .2s ease-in;
    }

    &:focus {
      outline: none;
    }

    &[aria-expanded="true"] {
      transition: all .2s ease-in;
      .table-data {
        border-bottom: 0;

        &:last-child {
          border-radius: 0;
        }
      }

      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .accordion__panel {
    display: flex;
    justify-content: space-between;
    padding: 15px 32px;
    margin-bottom: 15px;

    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    border-radius: 0 0 5px 5px;

    animation: fadein .3s ease-in;

    @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }

    p {
      margin: 5px 0;
    }
  }

  .accordion-panel-col {
    &:nth-child(2) {
      width: 58%;
    }

    &:last-child {
      width: 38%;
    }
  }

  .multiple-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .col-wrapper {
      &:last-child {
        p {
          width: 32%;

          &:not(:first-child) {
            text-align: right;
          }
        }
      }
    }
  }

  .col-wrapper {
    ul {
      padding: 0;
      list-style: none;
    }

    li {
      display: flex;
      justify-content: space-between;

      &:first-child {
        border-bottom: 2px solid $dark-gray;

        .data-label {
          font-size: 13px;
        }

        .data-value {
          font-size: 13px;
        }
      }

      &:not(:first-child) {
        border-bottom: 1px dotted $dark-gray;
      }
    }

    .title {
      margin-bottom: 0;
    }

    .data-label {
      font-size: 14px;
    }

    .case-link {
      .data-value {
        color: #1104C9;
        text-decoration: underline;
      }
    }

    .data-value {
      font-weight: 400;
      font-size: 14px;

      &.eth-usd {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        word-spacing: 4px; 

        .eth-usd-holder {
          display: flex;
          align-items: baseline;
        }

        .divider {
          display: block;
          margin: 0 7px;
        }

        .asset-value {
          margin-right: 5px;
        }
      }

      .asset-value {
        font-weight: 400;
        font-size: 8px;
      }

      &.with-score {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .risk-badge {
        margin-right: 15px;
        width: 8px;
        height: 8px;

        &.low {
          background-color: #36B885;
        }

        &.medium {
          background-color: #FFB502;
        }

        &.high {
          background-color: #ED4A47;
        }
      }
    }
  }

  .score-badge {
    display: flex;
    justify-content: center;
    padding: 2px 0;
    width: 35px;
    height: 25.57px;
    border-radius: 3px;



    border-radius: 5px;

    &.high {
      color: #ED4A47;

      background: #FFEFF0;
    }

    &.medium {
      color: #FFB502;
      background: #FFF9EC;
    }

    &.low {
      color: #36B885;
      background: #EDF7F3;
    }
  }
} 