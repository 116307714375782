.date-holder {
  position: relative;

  display: flex;
  padding: 8px 30px 7px 15px;
  width: 370px;

  border: 1px solid $light-grey;
  border-radius: 5px;

  cursor: pointer;

  p {
    font-size: 14px;

    &:first-child {
      margin-right: 25px;
    }
  }

  span {
    &:first-child {
      font-weight: 500;
    }
  }

  &:after {
    content: '';

    position: absolute;
    top: 50%;
    right: 15px;

    transform: translateY(-50%);

    width: 15px;
    height: 15px;

    background-image: url('../../assets/images/icon-calendar.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}