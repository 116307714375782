.uploadModalWindow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  z-index: 0;
}

.uploadModalWrapper {
  z-index: 0;

  margin-top: 79px;

  background-color: $alice-blue;
}

.uploadModalContent {
  .modal-body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0;
  }
}

.upload-type-wrapper {
  position: relative;

  padding-bottom: 77px;
  width: 533px;
  height: 530px;

  background: white;
  border: 1px solid #cbcbcb;
  border-radius: 5px;

  box-shadow: -2px 4px 12px rgba(0, 0, 0, 0.1);

  .btn-holder {
    position: absolute;
    bottom: 0;

    display: flex;
    justify-content: space-between;
    padding: 0 20px 20px;
    width: 100%;
  }

  .btn-upload {
    padding: 9px 0;
    width: 110px;

    font-weight: 400;
    font-size: 16px;
    color: #393E46;

    transition: all .3s ease-in-out;

    border: none;
    border-radius: 5px;

    &:nth-child(1) {
      position: relative;

      background-color: #F8F9FA;
      
      &:hover {
        background-color: #d0d0d0;
      }

      &:before {
        content: '';

        position: absolute;
        top: 50%;
        left: 22px;

        transform: translateY(-50%);

        width: 12px;
        height: 12px;

        background-image: url('../../assets/images/back-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
    
    &:nth-child(2){
      color: #fff;

      background-color: #412ED4;

      &:hover {
        opacity: .8;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: .3;
    }
  }
}

.upload-header {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #cbcbcb;

  p {
    font-size: 16px;
  }
}

.choose-upload-type {
  .modal {
    margin-top: 62px;
  }
}

.upload-option-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 36px;

  &.dropzone-spot {
    padding: 20px;

    .upload-icon {
      margin-bottom: 15px;
    }



    .file {
      list-style-type: none;
      padding-inline-start: 0;
      margin-top: 10px;
      font-size: 12px;
      font-weight: 300;

      &.error {
        height: 12px;
        color: #ff0000;
      }
    } 
  }
}

.kyc-type {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 31%;
  margin-right: 16px;
  margin-bottom: 16px;
  height: 73px;

  font-size: 10px;

  border: 1px solid #cbcbcb;
  border-radius: 5px;

  cursor: pointer;

  &:nth-child(5n) {
    margin-right: 0;
  }

  &.dashed {
    border-style: dotted;
  }

  p {
    position: absolute;
    max-width: 90px;
    margin: 0 auto;

    text-align: center;
  }
}

.kyc-image {
  width: 100%;
  height: 100%;

  border-radius: 10px;

  opacity: 0.6;
}

.upload-title,
.upload-subtitle {
  color: #606167;
}

.upload-title {
  font-weight: 500;
}

.upload-subtitle {
  font-weight: 300;
  font-size: 14px;
}

.notes-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px;
}

.note-item {
  flex: 0 0 48%;
}

.note-title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
}

.note-description {
  font-size: 12px;
  font-weight: 300;
}

.note-download {
  font-weight: 400;
  color: $blue;

  cursor: pointer;
}

.error-area {
  flex: 0 0 100%;
  margin: 0 auto;
  width: 533px;
  display: flex;
  position: relative;
  justify-content: center;
  top: -23px;

  .invalid-headers {
    padding-inline-start: 0;
    margin-top: 10px;

    list-style-type: none;
  }

  .error-list {
    width: 533px;

    p {
      padding: 15px;

      font-size: 14px;
      color: red;

      background-color: #ffd7d78a;
      border-radius: 5px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1049;
  opacity: .5;
  display: none;

  &.loading {
    display: block;
  }
}


div:has(.x) {
  background: red;
}