//main: ../main.scss

/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 125% / 1.4 $font-roboto;
}

.section-title {
  font-weight: 500;
  font-size: 30.4px;
  color: $dark-grayish-navy;
}

.title {
  margin: 0;

  font-weight: 500;
  font-size: 17px;
  color: $dark-gray;
}

.subtitle {
  margin: 0;

  font-weight: 500;
  font-size: 14px;
  color: $light-grey;
  line-height: 16px;
}

.t-h1 {
  font-size: 28px;
  font-weight: 800;
}

.t-h4 {
  font-size: 17px;
}

.modal-title {
  color: #393e46;
}

.table-row,
.arrowSort {
  color: #393e46;
}

.table-row {
  font-size: 15px;

  .currency {
    margin-right: 10px;

    font-size: 10px;
    text-transform: uppercase;
  }
}

.table-header {
  .title {
    font-weight: 400;
  }
}

.table-data {
  .table-row {
    font-weight: 100;
  }
}

.accordion__panel {
  p {
    font-size: 16px;
    color: $dark-gray;
    font-weight: 100;
  }

  .title {
    font-size: 16px;
    color: $dark-gray;
    font-weight: 500;
  }
}

.accordion-table {
  .accordion__button:after{
    color: #393e46
  }
} 