//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------


.home {
  .content {
    padding-top: 22px;
    // padding-top: 2%;
    background: #f8f9fb;
  }

  .chart-row-wrapper {
    // margin-bottom: 2%;
    margin-bottom: 0;
    padding-bottom: 22px;
  }
}
