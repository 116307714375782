.color {
  height: 19px;
  margin-left: 1px;
  margin-right: 1px;
  width: 12px;
}

.chroma-hash {
  display: flex;
  align-items: center;
}

.hash-text {
  display: none;
  word-break: break-word;
  font-weight: 700;

  & strong {
    margin-right: 10px;
  }

  & img {
    width: 17px;
    visibility: hidden;
  }
  
  &:hover img{
    visibility: visible;
  }
}

div.accordion__button[aria-expanded=true] {
  .table-data {
    .table-row {
      .chroma-hash {
        display: none;
      }

      // .accordion-table .table-row:first-child {
      //   width: 390px;
      // }

      .hash-text {
        display: block;
        position: absolute;
        width: 600px;
        left: 0;    
      }
    }
  }
}

