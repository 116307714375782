.modal {
  &.show {
    right: 0;

    .modal-dialog {
      right: 0;
    }
  }

  .form-group {
    margin-bottom: 2rem;
  }

  .t-h4 {
    margin-bottom: 11px;
    
    color: $black;
  }

  .modal-header {
    position: relative;
    
    padding: 1rem 2rem;

    .close {
      position: absolute;

      padding: 0;
      margin: 0;
    }

    .modal-title {
      margin-left: 50px;
    }
  }

  .icon {
    // top: 30%;
    top: 5px;
  }

  .modal-body {
    padding: 2.5rem 2rem;
  }

  .modal-dialog {
    position: absolute;
    right: -320px;
    
    margin: 0!important;
    width: auto;
    min-width: 484px;
    
    border: none;
    transform: translate3d(0%, 0, 0)!important;
    transition: opacity 0.3s linear, right 0.3s ease-out!important;
  }

  .modal-content {
    min-height: 100vh;
    
    background-color: #f8f9fb;
    border: none;
    border-radius: 0;
  }

  .modal-footer {
    padding: 1rem 2rem;
    justify-content: space-between;

    .btn-blue {
      padding: 6px 20px;
      max-width: 40%;
    }

    .dropdown-holder {
      max-width: 40%;

      .dropdown-toggle {
        max-width: 100%;
      }
    }
  }
}

@include respond-to (xs) {
  .modal {
    .modal-dialog {
      width: 100%;
      min-width: 100%;
    }

    .modal-footer {
      .dropdown-holder {
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
      }

      .btn-blue {
        max-width: 100%;
      }
    }
  }
}