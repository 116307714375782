.case-management {
  background-color: $alice-blue;

  .case-management-content {
    padding-top: 101px;
    min-height: calc(100vh);
  }

  .form-holder {
    padding: 50px 0;
  }

  .form-wrapper {
    background-color: #fff;
    border: 1px solid $light-grey;
    border-radius: 5px;
  }

  .form-header {
    position: relative;
    padding: 15px 15px 15px 60px;
    border-bottom: 1px solid $light-grey;

    &:before {
      content: '';

      position: absolute;
      top: 50%;
      left: 15px;

      transform: translateY(-50%);

      width: 30px;
      height: 30px;

      background-image: url('../../assets/images/icon-person.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      color: $dark-gray;
    }
  }

  .form-body {
    position: relative;
    
    display: flex;
    justify-content: space-between;
    padding-bottom: 68px;

    .form-body-col {
      &:first-child {
        width: 20%;

        border-right: 1px solid #e3e3e3;
      }

      &:last-child {
        padding: 30px 50px 70px;
        width: 80%;

        border-left: 1px solid #e3e3e3;
      }
    }

    .button-wrapper {
      padding-top: 30px;
    }

    .form-btn {
      width: 100%;
      min-height: 48px;

      font-weight: 400;
      font-size: 16px;
      color: #393E46;

      background-color: #fff;
      border: none;

      &.active {
        font-weight: 700;

        background-color: #e3e3e3;
      }
    }

    .tab-pane {
      .form-col-wrapper {
        display: flex;
        flex-wrap: wrap;
  
        &:last-child {
          .form-group {
            margin-bottom: 0;
          }
        }
      }

      &:first-child {
        .form-col-wrapper {
          &:not(:last-child) {
           .form-group {
             margin-bottom: 40px;
           }
         }
       }

       .form-group {
         width: 30%;

         &.company-name {
           width: 65%;
         }

         &:not(:first-child) {
           margin-left: 5%;
         }
       }
      }

      &:last-child {
        .form-col-holder {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .form-col-wrapper {
          flex-direction: column;
          align-items: flex-start;

          &:first-child {
            width: 30%;
          }

          &:last-child {
            width: 65%;
          }
        }

        .form-group {
          margin-bottom: 0;
          width: 100%;

          &:not(:first-child) {
            margin-top: 36px;
          }
        }

        textarea {
          min-height: 248px;
        }
      }
    }

    .form-body-heading {
      margin-bottom: 40px;
      width: 100%;

      font-weight: 700;
      font-size: 17px;
      color: #393E46;
    }

    .form-group {
      label {
        margin-bottom: 5px;

        font-weight: 400;
        font-size: 16px;
        color: #393E46;
      }

      input,
      select {
        padding: 7px 15px;
        font-size: 16px;
      }
    }
  }

  .form-footer {
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    
    border-top: 1px solid #e3e3e3;

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      padding: 10px 15px;
      margin-left: auto;
    }

    .form-btn {
      width: 100%;
      max-width: 110px;
      height: 40px;

      font-weight: 400;
      font-size: 16px;
      color: #fff;

      transition: all .3s ease-in-out;

      background-color: $blue;
      border: 1px solid $blue;
      border-radius: 5px;

      &:hover {
        opacity: .8;
      }

      // &:first-child {
      //   color: $dark-gray;
      //   background-color: transparent;

      //   &:hover {
      //     background-color: #f8f9fa;
      //   }
      // }

      // &:last-child {
      //   color: #fff;
      //   background-color: $blue;
      //   border: 1px solid $blue;

      //   &:hover {
      //     opacity: .8;
      //   }
      // }
    }
  }
}